import React from 'react';
import {connect} from 'react-redux';

import './styles.scss';
import classNames from 'classnames';
import moment from 'moment';

import {} from 'actions/index';

import {DASHARRAY_LENGTH, REDUCED_TIMERS} from 'constants/index';

class Countdown extends React.Component {
	constructor() {
		super();

		const duration = 10;
		this.state = {
			currentTime: duration,
			duration,
			isDone: false,
			isStarted: false,
		};

		this.tick = this.tick.bind(this);
	}

	componentWillMount() {
		const defaultDuration = 10;
		const duration = REDUCED_TIMERS ? defaultDuration : this.props.duration;

		if (duration) {
			this.setState(
				{
					currentTime: duration,
					duration,
				},
				() => {
					this.start();
				}
			);
		}
	}

	componentWillUnmount() {
		cancelAnimationFrame(this.animationFrame);
		this.setState({
			isDone: true,
		});
	}

	tick() {
		const isNow = moment().isSameOrAfter(this.expectedEnddate);
		if (isNow) {
			this.setState({
				isDone: true,
				currentTime: 0,
			});
			this.completeCallback();
		} else {
			let diff = Math.abs(moment().diff(this.expectedEnddate));

			this.setState({
				currentTime: diff,
			});
		}

		if (!this.state.isDone) {
			this.animationFrame = requestAnimationFrame(this.tick);
		}
	}

	start() {
		this.expectedEnddate = moment().add(this.state.duration, 'seconds');
		this.setState({
			isStarted: true,
		});
		this.tick();
	}

	completeCallback() {
		if (this.props.onComplete) {
			this.props.onComplete();
		}
	}

	render() {
		const {currentTime, duration, isDone, isStarted} = this.state;

		let formattedTime = moment(currentTime + (isDone ? 0 : 1000)).format(
			'm:ss'
		);

		let progress = 0;
		if (isStarted) {
			let percentageDone =
				((duration - currentTime / 1000) * 100) / duration;
			progress = (percentageDone * DASHARRAY_LENGTH) / 100;
		}

		return (
			<div
				className={classNames({
					'lit-circle': true,
					'lit-countdown': true,
				})}
			>
				<svg
					className="lit-circle__interactive"
					height="90"
					style={{enableBackground: 'new 0 0 90 90'}}
					version="1.1"
					viewBox="0 0 90 90"
					width="90"
					x="0px"
					xmlSpace="preserve"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
					y="0px"
				>
					<g transform="rotate(-84 45 45)">
						<circle
							className="lit-audio__outline"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="rgba(0,0,0,0.1)"
							strokeLinecap="round"
							strokeWidth="5"
						/>
						<circle
							className="lit-countdown__progress"
							cx="45"
							cy="45"
							fill="none"
							r="37"
							stroke="#fff"
							strokeLinecap="round"
							strokeWidth="6"
							style={{
								strokeDashoffset: `${progress}`,
							}}
						/>
					</g>
				</svg>
				<p className="lit-circle__text">
					<span>{formattedTime}</span>
				</p>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Countdown);
