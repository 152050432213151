import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import CustomLogin from '../CustomLogin';
import {initAuthAction} from 'actions/index';
import strings from '../../utils/strings';
import {authCallback} from 'utils/bankIdLogin';

import {
	FEIDE,
	IS_NORMING_VERSION,
} from 'constants/index';

import loginNoAuth from 'api/loginNoAuth';

const parsedSearch = queryString.parse(window.location.search);
const search = parsedSearch;
const params = new URLSearchParams(search);

const testKey = parsedSearch.key;
const authSuccess = parsedSearch.authSuccess;
const id = params.get('id');
/* const flag = JJiisTezRREXXDSSERllliOR321Frrsaas */

function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class Signon extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoginVisible: true,
			passwordLogin: false,
			isFeide:false,
			email: null,
			pinCode: null,
			childSelfStarting: null,
			showCoockieDetectionFrame: false,
			showAltLogin:
				/* id === 'JJiisTezRREXXDSSERllliOR321Frrsaas' || */
				id === 'child-exp-oms-test' ? true : false,
			altError: null,
		};
	}

	componentDidMount() {
		window.localStorage.removeItem('authtoken-screening');

		/* if (!testKey) {
			window.location.href = `/?error=true`;
			return;
		} */
		var receiveMessage = function (evt) {
			if (evt.data === 'MM:3PCunsupported') {
				/* document.getElementById(
					'start-fail-warn-ing'
				).innerHTML = `<img src=${warning} width="40px" height="30px">`; */
				document.getElementById('lit-site-warning').innerHTML =
					strings.warning2;
				document.getElementById('lit-site-warning_text').innerHTML =
					strings.warning2text;
				document.getElementById(
					'lit-site-settings-instructions'
				).innerHTML = strings.linkForInstructions;
				document.getElementById('lit-site-warning_text_end').innerHTML =
					strings.warning_text_end;
			} else if (evt.data === 'MM:3PCsupported') {
				document.getElementById('result').innerHTML = '';
			}
		};
		window.addEventListener('message', receiveMessage, false);

		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem('authtoken-screening', data.authToken);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
			} else {
				return;
			}
		}
	}

	initializeAltLogin = () => {
		this.setState({
			showAltLogin: true,
		});
	};
	onAltLoginFieldChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	submitAltLogin = (e) => {
		e.preventDefault();
		const {firstName, lastName} = this.state;
		const data = {testKey, firstName, lastName};

		loginNoAuth(data)
			.then((results) => {
				authCallback({data: JSON.stringify(results)});
			})
			.catch(() => {
				this.setState({
					altError: 'Ikke gyldig testkode',
				});
			});
	};

	render() {
		const {isLoginVisible, showAltLogin, altError,isFeide} = this.state;

		if (!isLoginVisible) {
			return null;
		}

		const {error, iframeUrl, initAuth, isInit} = this.props;

		const showIframe = isInit && iframeUrl && !showAltLogin;
		const showError = isInit && error && !showAltLogin;
		const showLoginButtons = !isInit && !showAltLogin;
		if (showIframe && isFeide) window.location.href = `${iframeUrl}`;
		return (
			<div className="Login">
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					<h2>Innlogging</h2>
					
						
                        <p>
                        Trykk på knappen for å logge på med feide
                        </p>
					
				
					{showLoginButtons && !this.state.passwordLogin && (
						<div className="bank-id-type-selector-wrapper">
							
			
							<br/>

							<button
								className="lit-btn lit-btn--small bg-secondary"
								onClick={() => {
									this.setState({
										showCoockieDetectionFrame: true,
										isFeide:true,
									});
									initAuth(FEIDE, testKey,true);
								}}
							>
								Feide
							</button>
							
							{IS_NORMING_VERSION && (
								<>
									<br />
									<br />
									<button
										className="lit-btn lit-btn--small bg-secondary"
										onClick={() => {
											this.initializeAltLogin();
										}}
									>
										Ingen innlogging
									</button>
								</>
							)}
						</div>
					)}
					<p className="lit-thirdPCsupport" id="result"></p>
					<div className="lit-thrid-party-settings">
						<p
							className="candidate-start-fail__instructions"
							style={{
								color: 'white',
								margin: 'auto',
								textAlign: 'center',
								fontSize: '18px',
							}}
						>
							<span
								id="start-fail-warn-ing"
								style={{justifyContent: 'center'}}
							></span>
							<span style={{color: 'Cyan', marginLeft: '2px'}}>
								<b>
									<span
										id="lit-site-warning"
										style={{marginLeft: '5px'}}
									></span>{' '}
								</b>
							</span>
							<em>
								<span id="lit-site-warning_text"></span>
							</em>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.dysmate.no/advarsel-2/"
							>
								<u style={{color: 'lime'}}>
									<span id="lit-site-settings-instructions"></span>
								</u>
							</a>
							<em>
								<span id="lit-site-warning_text_end"></span>
							</em>
						</p>
					</div>
					{showIframe && !isFeide && (
						<div className="bank-id-iframe-wrapper">
							<iframe
								title="login-frame"
								frameBorder="0"
								id="authFrame"
								scrolling="no"
								src={iframeUrl}
								style={{
									height: '600px',
									width: '100%',
								}}
							/>
						</div>
					)}
					{this.state.passwordLogin && (
						<div className="lit-customlogin-sub-wrapper">
							<CustomLogin />
						</div>
					)}
					{showError && <div>{error}</div>}
					{showAltLogin && (
						<div className="alternate-login">
							<p>
								For å logge inn uten BankID eller Feide trenger
								vi å vite hva du heter
							</p>
							<form onSubmit={this.submitAltLogin}>
								<label>
									Fornavn
									<input
										type="text"
										name="firstName"
										id="firstName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								<label>
									Etternavn
									<input
										type="text"
										name="lastName"
										id="lastName"
										onChange={this.onAltLoginFieldChange}
									/>
								</label>
								{altError && (
									<p className="error">{altError}</p>
								)}
								<button
									className="lit-btn lit-btn--small"
									type="submit"
								>
									Logg inn
								</button>
							</form>
						</div>
					)}
					{this.state.showCoockieDetectionFrame && (
						<div>
							<iframe
								src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
								style={{display: 'none'}}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {error, iframeUrl, isInit} = state.auth;

	return {
		error,
		iframeUrl,
		isInit,
	};
};

const mapDispatchToProps = {
	initAuth: initAuthAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signon);
