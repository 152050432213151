const pauseAndReset = (audioElem) => {
	if (!audioElem) {
		return;
	}
	audioElem.pause();
	audioElem.currentTime = 0;
};

const playPause = (audioElem, otherAudioElemsObject = {}) => {
	if (audioElem.paused) {
		Object.keys(otherAudioElemsObject).forEach((key) => {
			pauseAndReset(otherAudioElemsObject[key]);
		});

		audioElem.play().catch((error)=>{
			console.log('something went wrong', error);
		});
	} else {
		pauseAndReset(audioElem);
	}
};

export default playPause;
