import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import {isTablet} from 'react-device-detect';
import Gif from 'assets/rotation-from-horizontal-to-vertical.gif';
import strings from 'utils/strings';
import {begin} from 'utils/beforeunload';
import Introduksjon from 'components/Introduksjon';
import Orddiktat from 'components/Orddiktat';
import Ordkjedetesten from 'components/Ordkjedetesten';
import Tulleordtesten from 'components/Tulleordtesten';
import Skrivehastighet from 'components/Skrivehastighet';
import Leseproven from 'components/Leseproven';
import Ordforstaelse from 'components/Ordforstaelse';
import Ferdig from 'components/Ferdig';
import getTestInfo from 'api/getTestInfo';
import getUserInfo from 'api/getUserInfo';
import getUserGrade from 'api/getUserGrade';
import {setTestCandidateGrade} from 'actions/index';

const testSteps = [
	Introduksjon,
	Orddiktat,
	Ordkjedetesten,
	Tulleordtesten,
	Skrivehastighet,
	Leseproven,
	Ordforstaelse,
	Ferdig,
];
const renderRemainingTests = [];

class ScreeningTest extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoggedIn: false,
			needLanguageSetting: false,
			includeAnamnese: false,
			isPortrait: true,
			isRotateDeviceFirstTimeRendered: true,
		};
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		getTestInfo().then((testInfo) => {
			const exclusiveTests = testInfo.exclusiveTests;
			const missingTests = [];
			const includeLongIntro = testInfo.anamnese ===1;
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					// eslint-disable-next-line default-case
					switch (testToContinue[i]) {
						case 1:
							renderRemainingTests.push(Orddiktat);
							break;
						case 2:
							renderRemainingTests.push(Ordkjedetesten);
							break;
						case 3:
							renderRemainingTests.push(Tulleordtesten);
							break;
						case 4:
							renderRemainingTests.push(Skrivehastighet);
							break;
						case 5:
							renderRemainingTests.push(Leseproven);

							break;
						case 6:
							renderRemainingTests.push(Ordforstaelse);

							break;
					}
					if (i === testToContinue.length) {
						renderRemainingTests.push(Ferdig);
					}
				}
				this.setState({
					needLanguageSetting: true,
					testsToTake: missingTests,
					includeAnamnese:includeLongIntro
				});
				getUserGrade().then((results) => {
					this.props.onSetTestCandidateGrade(results);
				});
			}
			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
						includeAnamnese:includeLongIntro
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}
	componentWillUnmount() {
		window.addEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		if (window.innerHeight > window.innerWidth) {
			this.setState({isPortrait: true});
		} else {
			this.setState({isPortrait: false});
		}
	};
	onSelectLanguage(lang) {
		strings.setLanguage(lang);
		this.setState({
			needLanguageSetting: false,
		});
	}


	render() {
		const {isLoggedIn,isPortrait,isRotateDeviceFirstTimeRendered,includeAnamnese} = this.state;

		if (!isLoggedIn) {
			return null;
		}

		const {currentStepIndex, onSetTestCandidateGrade} = this.props;

		if (renderRemainingTests.length) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		} else {
			var CurrentStep = testSteps[currentStepIndex];
		}
		if (!isPortrait && isRotateDeviceFirstTimeRendered) {
			setTimeout(() => {
				this.setState({isRotateDeviceFirstTimeRendered: false});
			}, 3000);
		}
		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate-logo"
						/>
					</h1>
				</header>
				{Boolean(renderRemainingTests.length) &&
					this.state.needLanguageSetting && (
						<div className="lit-test-intro">
							<h1>{`Velg/vel språk`}</h1>

							<button
								className="lit-test-intro-language-button"
								tabIndex="1"
								onClick={() => this.onSelectLanguage('no')}
							>
								{`Norsk Bokmål`}
							</button>
							<button
								className="lit-test-intro-language-button"
								tabIndex="2"
								onClick={() => this.onSelectLanguage('nn')}
							>
								{`Norsk Nynorsk`}
							</button>
						</div>
					)}
				{isRotateDeviceFirstTimeRendered && !isPortrait && isTablet && (
						<div className="lit-test-rotation-gif">
							<img src={Gif} alt="rotate tablet gif" />
						</div>
					)}
				{!this.state.needLanguageSetting && (
					<div className="lit-wrapper--article text-center">
					<CurrentStep
						includeLongAnamnese={includeAnamnese}
					/>
				</div>
				)}
				
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};
const mapDispatchToProps = {
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTest);
