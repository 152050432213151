import {ENDPOINT_URL} from 'constants/index';
const headers = new Headers();
headers.append('Content-Type', 'application/json');

export default function createTest(type) {
	return fetch(`${ENDPOINT_URL}/newtestkey`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body: JSON.stringify({type}),
	})
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
			let errorMessage;
			if (response.status === 500) {
				errorMessage = 'Ikke gyldig testnøkkel ';
				return Promise.reject(errorMessage);
			}

			return Promise.reject(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
}
