import React from 'react';

// import './styles.scss';

import {end} from 'utils/beforeunload';
import strings from 'utils/strings';

class Ferdig extends React.Component {
	componentDidMount() {
		end();
		window.localStorage.removeItem('authtoken-screening');
		setTimeout(function () {
			window.location = '/';
		}, 10000);
	}

	render() {
		return (
			<div>
				<h1 className="lit-page-title">{strings.done}</h1>
				<p className="lit-page-description">{strings.doneCongrats}</p>
			</div>
		);
	}
}

export default Ferdig;
