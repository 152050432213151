import getFetchOptions from 'utils/getFetchOptions';

import {ENDPOINT_URL} from 'constants/index';

export default function getUserInfo() {
	return fetch(
		`${ENDPOINT_URL}/testuser/self`,
		getFetchOptions({
			method: 'GET',
		})
	);
}
