import React from 'react';
import {connect} from 'react-redux';
import strings from 'utils/strings';

import {updateObject} from 'utils/reducerUtils';

import AudioPlayer from 'components/AudioPlayer';

import * as noTutorial from './languages/no-tutorial.js';
import * as nnTutorial from './languages/nn-tutorial.js';

const tutorialObj = {
	no: noTutorial,
	nn: nnTutorial,
};

class Tutorial extends React.Component {
	constructor() {
		super();

		this.tutorial = tutorialObj[strings.getLanguage()];
		this.tutorialAssignment = this.tutorial.tutorialAssignment;
		this.assignments = this.tutorial.assignments;
		this.exampleSound = this.tutorial.exampleSound;
		this.dinTurSound = this.tutorial.dinTurSound;
		this.starterTestenSound = this.tutorial.starterTestenSound;

		const results = {};

		for (let assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			hasPlayedFirstAudio: false,
			results,
		};

		this.audioRefs = {};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {hasPlayedFirstAudio, results} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			key => results[key]
		);
		const isNextButtonVisible = allResultsHasValues;

		return (
			<div className="lit-test">
				<div className="lit-tulleord lit-tulleord__tutorial">
					<p className="instruction first">
						<AudioPlayer
							autoplay
							id="exampleSound"
							inline
							onComplete={() => {
								if (!hasPlayedFirstAudio) {
									this.setState({
										hasPlayedFirstAudio: true,
									});

									this.dinTurAudioPlayer.handleStartPlayer();
								}
							}}
							refs={this.audioRefs}
							src={this.exampleSound}
						/>
						{strings.formatString(
							strings.tulleordtesteneksempel1,
							<strong>
								omkel, helefopter, øsk, sgo og drapt
							</strong>,
							<strong>sgo</strong>
						)}
					</p>
					<div className="lit-words-wrapper demonstration">
						<div className="lit-words">
							<span className="lit-input__label lit-input__label--count">
								1
							</span>
							<div className="lit-word-list">
								{this.tutorialAssignment.choices.map(
									(choice, i) => {
										const idWord = i + choice.word;
										return (
											<span
												className="lit-word"
												key={idWord}
											>
												<input
													checked={choice.isCorrect}
													className="lit-word__field"
													id={idWord}
													onChange={e => {
														e.preventDefault();
													}}
													type="radio"
												/>
												<label
													className="lit-word__label"
													htmlFor={idWord}
												>
													<div className="checkbox" />
													{choice.word}
												</label>
											</span>
										);
									}
								)}
							</div>
						</div>
					</div>
					<p className="instruction">
						<AudioPlayer
							inline
							ref={ref => {
								this.dinTurAudioPlayer = ref;
							}}
							refs={this.audioRefs}
							src={this.dinTurSound}
						/>
						{strings.tulleordCTA}
					</p>
					{this.assignments.map(assignment => {
						const {id, choices} = assignment;

						const currentAssignmentValue = results[id];
						let comment = null;
						for (let choice of choices) {
							if (currentAssignmentValue === choice.word) {
								comment = (
									<p className="instruction">
										<AudioPlayer
											autoplay
											inline
											onComplete={() => {
												if (
													this.narDuStarterAudioPlayer
												) {
													this.narDuStarterAudioPlayer.handleStartPlayer();
												}
											}}
											refs={this.audioRefs}
											src={choice.sound}
										/>
										{choice.comment}
									</p>
								);
							}
						}

						return (
							<div className="lit-words-wrapper" key={id}>
								<div className="lit-words">
									<span className="lit-input__label lit-input__label--count">
										{id}
									</span>
									<div className="lit-word-list">
										{choices.map((choice, i) => {
											const idWord = i + choice.word;
											return (
												<span
													className="lit-word"
													key={idWord}
												>
													<input
														checked={
															currentAssignmentValue ===
															choice.word
														}
														className="lit-word__field"
														id={idWord}
														onChange={() =>
															this.setResults(
																id,
																choice.word
															)
														}
														type="radio"
													/>
													<label
														className="lit-word__label"
														htmlFor={idWord}
													>
														<div className="checkbox" />
														{choice.word}
													</label>
												</span>
											);
										})}
									</div>
								</div>
								{comment}
							</div>
						);
					})}
				</div>
				{isNextButtonVisible && (
					<p className="instruction">
						<AudioPlayer
							inline
							ref={ref => {
								this.narDuStarterAudioPlayer = ref;
							}}
							refs={this.audioRefs}
							src={this.starterTestenSound}
						/>
						{strings.tulleordStarterTesten}
					</p>
				)}
				{isNextButtonVisible && (
					<button
						className="lit-btn bg-primary"
						onClick={() => onNextStep(results)}
					>
						{strings.startTest}
					</button>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Tutorial);
