import React, {Component} from 'react';
import AudioPlayerExperimental from 'components/AudioPlayerExpreimental';
import Test from './Test';
import sendBase64Data from 'api/sendBase64Data';
import sendAudioResponse from 'api/sendAudioResponse';
import Ferdig from 'components/Ferdig';
import openingSound from './assets/opening-1.m4a';
import silenceFile from './assets/silence.mp3';
import gradeQuery from './assets/question-grade.m4a';
import failureGradeFetch from './assets/failure_proc_grade.m4a';
import failureAgeFetch from './assets/failure_proc_age.m4a';
import finalSound from './assets/finish_sound.m4a';
import videoTutorialSound from './assets/intro-video-sound.m4a';
import repeatQuestionSound from './assets/repeatQuestion.m4a';
import {ReactComponent as Dysmate} from './assets/dysmate.svg';
import avatar from './assets/talking_greeting.gif';
import colorListen from './assets/colorListen.gif';
import avatarStill from './assets/avatarStill.gif';
import Loader from './Loader';
import applauseImage from './assets/applause.png';
var audioChunks = [];
var audioBits = [];
const mainArray = [];
var silence = true;
var timeLimit = false;
let initialSilence = true;
var counter = 0;
var silence_delay = 0;
var min_decibels = -55;
let data = null;
//use external audio api for resampling the audio data
class MinuteSpeech extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentSound: openingSound,
			avatar: avatar,
			currentFetchCounter: 'age',
			renderIntro: false,
			renderTest: false,
			currentStep: 0,
			currentItem: 0,
			timer: 0,
			age: null,
			grade: null,
			isFinished: false,
			renderLoader: false,
		};

		this.gatherChunkedAudio = this.gatherChunkedAudio.bind(this);
	}
	createAudioContext = (desiredSampleRate) => {
		var AudioCtor = window.AudioContext || window.webkitAudioContext;

		desiredSampleRate =
			typeof desiredSampleRate === 'number' ? desiredSampleRate : 44100;
		var context = new AudioCtor({sampleRate: 16000});
		if (context.sampleRate !== desiredSampleRate) {
			var buffer = context.createBuffer(1, 1, desiredSampleRate);
			var dummy = context.createBufferSource();
			dummy.buffer = buffer;
			dummy.connect(context.destination);
			dummy.start(0);
			dummy.disconnect();

			context.close(); // dispose old context
			context = new AudioCtor({sampleRate: desiredSampleRate});
		}
		return context;
	};

	detectSilence = (
		stream,
		rec,
		onSoundEnd = (_) => {},
		onSoundStart = (_) => {}
	) => {
		/* setTimeout(() => {
			timeLimit = true;
			if (rec.state !== 'inactive') {
				rec.stop();
			}

			this.gatherChunkedAudio(rec);
		}, 61000); */
		/* var AudioContext = window.AudioContext || window.webkitAudioContext; */
		/* const ctx = new AudioContext(); */
		const ctx = this.createAudioContext(48000);
		var actual_sample_rate = ctx.sampleRate;
		/* if (actual_sample_rate != 48000) {
			ctx.sampleRate = 48000;
		} */
		const analyser = ctx.createAnalyser();
		const gatherAllChunks = this.gatherChunkedAudio.bind(this);
		const streamNode = ctx.createMediaStreamSource(stream);
		streamNode.connect(analyser);
		analyser.minDecibels = min_decibels;

		data = new Uint8Array(analyser.frequencyBinCount);

		let silence_start = performance.now();
		let triggered = false;
		rec.start();
		function gatherChunks(rec) {
			gatherAllChunks(rec);
		}

		function loop(time) {
			rec.ondataavailable = (e) => {
				if (!initialSilence) {
					audioChunks.push(e.data);
				}
			};
			rec.onstop = (e) => {
				if (!timeLimit) {
					if (audioChunks.length) audioBits.push({audioChunks});
					audioChunks = [];

					if (initialSilence) {
						rec.start();
					} else {
						timeLimit = true;
						gatherChunks(rec);
					}
				}
			};
			rec.onstart = (e) => {
				console.log(' started');
			};
			if (!timeLimit) requestAnimationFrame(loop);
			analyser.getByteFrequencyData(data);
			if (data.some((v) => v)) {
				if (triggered) {
					triggered = false;
					onSoundStart();
				}
				silence_start = time;
			}
			if (!triggered && time - silence_start > silence_delay) {
				if (rec.state !== 'inactive') {
					rec.stop();
				}

				onSoundEnd(analyser);
				triggered = true;
			}
		}

		loop();
	};

	onSilence = (analyser) => {
		counter = counter + 1;
	};
	onSpeak = () => {
		initialSilence = false;
		silence_delay = 1000;
	};

	gatherChunkedAudio(rec) {
		if (rec.state !== 'inactive') {
			rec.stop();
		}
		this.processChunkedAudio('finalAudioChunk');
		rec.ondataavailable = (e) => {
			audioChunks = [];
			audioChunks.push(e.data);
			const finalAudioChunk = e.data;
		};
	}
	processChunkedAudio(lastChunk) {
		let baseArray = [];
		let base64Obj = {};
		let promises = [];
		if (audioChunks.length) audioBits.push({audioChunks});
		const arr = [];

		const chunkSize = audioBits.length - 1;
		for (let i = 0; i < audioBits.length; i++) {
			arr.push(audioBits[i].audioChunks[0]);
			var blobdata = new Blob(audioBits[i].audioChunks, {
				type: 'audio/webm;codecs=opus',
			});
		}

		var blob = new Blob(arr, {
			type: 'audio/webm;codecs=opus',
		});

		/* var blob = new Blob(audioBits[chunkSize].audioChunks, {
			type: 'audio/webm',
		}); */

		for (let i = 0; i < audioBits.length; i++) {
			let blobArray = new Blob(audioBits[i].audioChunks, {
				type: 'audio/webm;codecs=opus',
			});
			let filePromise = new Promise((resolve) => {
				let reader = new FileReader();
				reader.readAsDataURL(blobArray);
				reader.onload = () => resolve(reader.result);
			});
			promises.push(filePromise);
		}
		Promise.all(promises).then((fileContents) => {
			const baseArray = {
				baseData: fileContents,
				fetchItem: this.state.currentFetchCounter,
			};
			this.sendEncodedData(baseArray);
		});
	}

	sendEncodedData(base64Data) {
		sendAudioResponse(base64Data).then((res) => {
			this.renderNextSound(res);
		});
	}
	fetchAudioResponse() {
		if (this.state.currentFetchCounter === 'Test-finished') {
			this.setState({isFinished: true, renderTest: false});
			return;
		}
		this.setState({avatar: colorListen});
		if (
			this.state.currentFetchCounter === 'age' ||
			this.state.currentFetchCounter === 'grade'
		) {
			timeLimit = false;
			initialSilence = true;
			audioChunks = [];
			audioBits = [];
			silence_delay = 0;
			var options = {
				mimeType: 'audio/webm;codecs=opus',
			};
			const constraints = {
				audio: {
					channelCount: 1,
					sampleRate: 44100,
					sampleSize: 16,
					volume: 1,
				},
			};
			var device = navigator.mediaDevices.getUserMedia({
				audio: true,
			});

			device.then((stream) => {
				const audioTracks = stream.getAudioTracks();
				const audioTrack = audioTracks[0];
				try {
					audioTrack.applyConstraints(constraints);
				} catch (e) {
					console.log('error', e);
				}

				var rec = new MediaRecorder(stream, options);
				this.detectSilence(stream, rec, this.onSilence, this.onSpeak);
			});
		} else if (this.state.currentFetchCounter === 'No-fetch') {
			setTimeout(() => {
				this.setState({
					renderTest: true,
				});
			}, 100);
		}
	}
	renderNextSound(res) {
		let currentObjectItem = this.state.currentFetchCounter;
		switch (currentObjectItem) {
			case 'age':
				if (
					res.requestStatus === 'success' &&
					res.hasOwnProperty('ageTranscription')
				) {
					this.setState({
						age: res.ageTranscription,
						currentFetchCounter: 'grade',
						currentSound: gradeQuery,
						avatar: avatarStill,
					});
				} else {
					this.toggleAudioInstruction(currentObjectItem);
				}
				currentObjectItem = '';
				break;
			case 'grade':
				if (
					res.requestStatus === 'success' &&
					res.hasOwnProperty('gradeTranscription')
				) {
					this.setState({
						grade: res.gradeTranscription,
						currentSound: videoTutorialSound,
						currentFetchCounter: 'No-fetch',
						avatar: avatarStill,
					});
				} else {
					this.toggleAudioInstruction(currentObjectItem);
				}
				break;
		}
	}
	toggleAudioInstruction(currentObjectItem) {
		if (
			currentObjectItem === 'age' &&
			this.state.currentSound === openingSound
		) {
			this.setState({currentSound: failureAgeFetch, avatar: avatarStill});
			return;
		}
		if (
			currentObjectItem === 'age' &&
			this.state.currentSound === failureAgeFetch
		) {
			this.setState({
				currentSound: repeatQuestionSound,
				avatar: avatarStill,
			});
			return;
		}
		if (
			currentObjectItem === 'age' &&
			this.state.currentSound === repeatQuestionSound
		) {
			this.setState({currentSound: failureAgeFetch, avatar: avatarStill});
			return;
		}
		if (
			currentObjectItem === 'grade' &&
			this.state.currentSound === gradeQuery
		) {
			this.setState({
				currentSound: failureGradeFetch,
				avatar: avatarStill,
			});
			return;
		}
		if (
			currentObjectItem === 'grade' &&
			this.state.currentSound === failureGradeFetch
		) {
			this.setState({
				currentSound: repeatQuestionSound,
				avatar: avatarStill,
			});
			return;
		}
		if (
			currentObjectItem === 'grade' &&
			this.state.currentSound === repeatQuestionSound
		) {
			this.setState({
				currentSound: failureGradeFetch,
				avatar: avatarStill,
			});
			return;
		}
		//Here we manage what audio instructions are presentated to the candidate
	}
	sendDataForTranscription(base64Data) {
		this.setState(
			{
				renderLoader: true,
				renderTest: false,
			},
			() => {
				sendBase64Data(base64Data).then(() => {
					this.setState({
						currentSound: finalSound,
						avatar: applauseImage,
						currentFetchCounter: 'Test-finished',
						renderTest: false,
						renderLoader: false,
					});
				});
			}
		);
	}

	render() {
		let videoRef;
		const soundSource = this.state.currentSound;
		const currentAvatar = this.state.avatar;
		console.log(
			'checking when loader lands and what else happens',
			this.state.renderLoader
		);
		return (
			<div className="SpeechTestWrapper">
				{!this.state.renderTest &&
					!this.state.isFinished &&
					!this.state.renderLoader && (
						<div className="lit-screening-speech-intro">
							<p>
								<AudioPlayerExperimental
									autoPlay
									inline
									playsinline=""
									onPause={() => {
										videoRef.play();
									}}
									onComplete={() => {
										this.fetchAudioResponse();
									}}
									src={soundSource}
								/>
							</p>

							{/* <Dysmate
								fill="yellow"
								width="90%"
								height="80%"
								viewBox="0 0 778.000000 1154.000000"
							/> */}
							<img src={currentAvatar} alt="loading..." />
						</div>
					)}
				{this.state.renderTest &&
					!this.state.isFinished &&
					!this.renderLoader && (
						<Test
							age={this.state.age}
							grade={this.state.grade}
							onSendDataForTranscription={(base64Data) => {
								this.sendDataForTranscription(base64Data);
							}}
						/>
					)}
				{!this.state.renderTest &&
					!this.state.isFinished &&
					this.state.renderLoader && <Loader />}
				{this.state.isFinished && <Ferdig />}

				{/* {this.getUserAnswer()} */}
			</div>
		);
	}
}
export default MinuteSpeech;
