/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable space-before-blocks */
import React from 'react';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import createTest from 'api/createTest';
import {IS_NORMING_VERSION} from 'constants/index';

const parsedSearch = queryString.parse(window.location.search);
const authSuccess = parsedSearch.authSuccess;
const error = parsedSearch.error;
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
class LandingPageMunicipality extends React.Component {
	constructor() {
		super();

		this.state = {
			testKey: '',
		};
    }
    

	/* componentDidMount(){
		if (authSuccess) {
			let data = atob(
				decodeURIComponent(
					window.location.search.substr(
						window.location.search.indexOf('=') + 1
					)
				)
			);

			if (data && IsValidJSONString(data)) {
				return new Promise((resolve, reject) => {
					data = JSON.parse(data);

					if (data.authError) {
						reject({message: 'There was an error logging you in'});
						return;
					}

					if (
						data &&
						data.action === 'auth' &&
						data.result === 'OK'
					) {
						localStorage.setItem('authtoken-screening', data.authToken);

						window.location.href = `/test`;
						resolve();
						return;
					}
					reject({message: `Kunne ikke logge inn!`});
				});
            } 
            else {
				return;
			}
		}
    } */
    
    generateTestKeyonFly(){
		const type='youth-screening';
		createTest(type).then((res)=>{
			if(res.length){
				const result = res[0];
				this.setState({testKey:result.keyString});
				window.location.href = `/signon?key=${result.keyString}`;
			}
		})
		.catch((e)=>{
			console.log('error',e);
		});
	}

	render() {
		const {testKey} = this.state;

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="Literate Logo"
						/>
					</h1>

					<div className="right">
						<a className="lit-link" href="http://admin.literate.no">
							Administrator
						</a>
					</div>
				</header>
				{IS_NORMING_VERSION && (
					<>
						<h1 className="lit-landing-page-title">Ungdomstest</h1>
						<p className="lit-landing-page-subtitle">
							NORMERINGSVERSJON
						</p>
					</>
				)}
				{!IS_NORMING_VERSION && (
					<>
						<h1 className="lit-page-title">Ungdomstest</h1>
					</>
				)}
				<p className="lit-page-description">
                Husk at du må være en elev på ungdomsskole for å ta denne test.
				</p>
               
				<div className="lit-wrapper--article">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							this.generateTestKeyonFly();
							
						}}
					>
						<div className="lit-input inline">
                        <div className="lit-page-description">
							{/* <label className="lit-input__label tiny">
								Din kode:
							</label>
							<input
								className="lit-input__field"
								onChange={(event) => {
									const value = event.target.value;
									this.setState({
										testKey: value,
									});
								}}
								placeholder="F.eks 2D84FCA5"
								required
								type="text"
								value={testKey} 
							/>*/}
                            
							<button className="lit-btn bg-secondary">
								Start
							</button>
                            </div>
						</div>
						{Boolean(error) && (
							<span>En gyldig nøkkel kreves for å logge inn</span>
						)}
					</form>
				</div>
			</div>
		);
	}
}

export default LandingPageMunicipality;
