import React from 'react';
import {connect} from 'react-redux';

import strings from 'utils/strings';

import AudioPlayer from 'components/AudioPlayer';

import audioNo from 'assets/sounds/no/5 1 Leseforstaelse Intro.mp3';
import audioNn from 'assets/sounds/nn/5 1 Leseforstaelse Intro.wav';

import tutorialVideo from './assets/99c06a75c2d10f2ad5d81a1aa870fc65.mp4';

class Intro extends React.Component {
	constructor() {
		super();

		const audioObj = {
			no: audioNo,
			nn: audioNn,
		};

		this.audio = audioObj[strings.getLanguage()];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div>
				<div className="text-left">
					<p>
						<AudioPlayer
							autoplay
							inline
							onPause={() => {
								videoRef.play();
							}}
							src={this.audio}
						/>
						{strings.leseprovenIntro}
					</p>
					<p>{strings.leseprovenTimeLimit}</p>
				</div>

				<div className="lit-preview">
					<p className="lit-preview__label">
						{strings.testLooksLikeThis}
					</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={tutorialVideo}
					/>
				</div>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
