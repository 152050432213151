import audio1 from 'assets/sounds/nn/diktat/01.wav';
import audio2 from 'assets/sounds/nn/diktat/02.wav';
import audio3 from 'assets/sounds/nn/diktat/03.wav';
import audio4 from 'assets/sounds/nn/diktat/04.wav';
import audio5 from 'assets/sounds/nn/diktat/05.wav';
import audio6 from 'assets/sounds/nn/diktat/06.wav';
import audio7 from 'assets/sounds/nn/diktat/07.wav';
import audio8 from 'assets/sounds/nn/diktat/08.wav';
import audio9 from 'assets/sounds/nn/diktat/09.wav';
import audio10 from 'assets/sounds/nn/diktat/10.wav';
import audio11 from 'assets/sounds/nn/diktat/11.wav';
import audio12 from 'assets/sounds/nn/diktat/12.wav';
import audio13 from 'assets/sounds/nn/diktat/13.wav';
import audio14 from 'assets/sounds/nn/diktat/14.wav';
import audio15 from 'assets/sounds/nn/diktat/15.wav';
import audio16 from 'assets/sounds/nn/diktat/16.wav';
import audio17 from 'assets/sounds/nn/diktat/17.wav';
import audio18 from 'assets/sounds/nn/diktat/18.wav';
import audio19 from 'assets/sounds/nn/diktat/19.wav';
import audio20 from 'assets/sounds/nn/diktat/20.wav';
import audio21 from 'assets/sounds/nn/diktat/21.wav';
import audio22 from 'assets/sounds/nn/diktat/22.wav';
import audio23 from 'assets/sounds/nn/diktat/23.wav';
import audio24 from 'assets/sounds/nn/diktat/24.wav';
import audio25 from 'assets/sounds/nn/diktat/25.wav';
import audio26 from 'assets/sounds/nn/diktat/26.wav';
import audio27 from 'assets/sounds/nn/diktat/27.wav';
import audio28 from 'assets/sounds/nn/diktat/28.wav';
import audio29 from 'assets/sounds/nn/diktat/29.wav';
import audio30 from 'assets/sounds/nn/diktat/30.wav';
const audioFiles = {
	1: audio1,
	2: audio2,
	3: audio3,
	4: audio4,
	5: audio5,
	6: audio6,
	7: audio7,
	8: audio8,
	9: audio9,
	10: audio10,
	11: audio11,
	12: audio12,
	13: audio13,
	14: audio14,
	15: audio15,
	16: audio16,
	17: audio17,
	18: audio18,
	19: audio19,
	20: audio20,
	21: audio21,
	22: audio22,
	23: audio23,
	24: audio24,
	25: audio25,
	26: audio26,
	27: audio27,
	28: audio28,
	29: audio29,
	30: audio30,
};

export default audioFiles;
