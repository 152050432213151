import audio2 from 'assets/sounds/no/2 3 Ordkjedetest-Feil-los-tupp-meg-sa.mp3';
import audio3 from 'assets/sounds/no/2 4 Ordkjedetest-Feil-ost-par-vask-jakt.mp3';
import audio4 from 'assets/sounds/no/2 6 Ordkjedetest-Feil-hus-grei.mp3';

const assignments = [
	{
		id: 1,
		text: 'påske last mil fra',
		answer: 'påske last mil fra',
	},
	{
		id: 2,
		text: 'løstuppmegsa',
		answer: 'løs tupp meg sa',
		audio: audio2,
		count: 'andre',
		hasPlayed: false,
	},
	{
		id: 3,
		text: 'ostparvaskjakt',
		answer: 'ost par vask jakt',
		audio: audio3,
		count: 'tredje',
		hasPlayed: false,
	},
	{
		id: 4,
		text: 'husgreisåsær',
		answer: 'hus grei så sær',
		audio: audio4,
		count: 'fjerde',
		hasPlayed: false,
	},
];
export default assignments;
