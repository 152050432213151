import React from 'react';
import {connect} from 'react-redux';

import AudioPlayer from 'components/AudioPlayer';

import strings from 'utils/strings';

// import './styles.scss';

import audioNo from 'assets/sounds/no/6 1 Ordforstaelse Intro.mp3';
import audioNn from 'assets/sounds/nn/6 1 Ordforstaelse Intro.mp3';

import tutorialVideo from './assets/e845140cdea968bfb0a8144fb06a7064.mp4';

const audioObj = {
	no: audioNo,
	nn: audioNn,
};

class Intro extends React.Component {
	constructor() {
		super();
		this.audio = audioObj[strings.getLanguage()];
	}

	render() {
		const {onNextStep} = this.props;

		let videoRef;

		return (
			<div>
				<div className="text-left">
					<p>
						<AudioPlayer
							autoplay
							inline
							onPause={() => {
								videoRef.play();
							}}
							src={this.audio}
						/>
						{strings.ordforstaelseIntro}
					</p>
					<p>{strings.ordforstaelseTimelimit}</p>
				</div>
				<div className="lit-preview">
					<p className="lit-preview__label">
						{strings.testLooksLikeThis}
					</p>
					<video
						className="lit-preview__video"
						loop
						ref={(ref) => {
							videoRef = ref;
						}}
						src={tutorialVideo}
					/>
				</div>
				<button
					className="lit-btn bg-primary"
					onClick={() => onNextStep()}
				>
					{strings.start}
				</button>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Intro);
