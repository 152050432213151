import {ENDPOINT_URL, UNAUTHORIZED, WRONGKEY} from 'constants/index';

const headers = new Headers();
headers.append('Content-Type', 'application/json');
function IsValidJSONString(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
export const authCallback = (event) => {
	let data = event.data ? event.data : null;
	if (event.data && IsValidJSONString(event.data)) {
		data = JSON.parse(event.data);
	} else {
		return;
	}
	if (data && data.action === 'auth' && data.result === 'OK') {
		window.removeEventListener('message', authCallback);
		localStorage.setItem('authtoken-screening', data.authToken);
		if (data.expFlag) {
			window.location.href = `/oneminutespeech`;
		} else {
			window.location.href = `/test`;
		}
	}
};
/* loginNoAuth */

export const initAuth = (method, key,municipal) => {
	const body = JSON.stringify({
		method,
		key,
		municipal,
		origin: 'youthTest',
	});
	return fetch(`${ENDPOINT_URL}/initAuthWithKey`, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers,
		body,
	}).then((response) => {
		if (response.ok) {
			window.addEventListener('message', authCallback);
			return response.json();
		}
		let errorMessage;
		if (response.status === UNAUTHORIZED) {
			errorMessage = 'Ikke gyldig testnøkkel';
		} else if (response.status === WRONGKEY) {
			errorMessage = 'Gyldig testnøkkel, men feil type test';
		} else if (response.statusText) {
			errorMessage = response.statusText;
		} else {
			errorMessage = 'En ukjent feil har skjedd';
		}
		return Promise.reject(errorMessage);
	});
};
