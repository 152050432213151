import {ENDPOINT_URL} from 'constants/index';
/* import {apiErrorHandling} from 'utils/apiUtils'; */

import getFetchOptions from 'utils/getFetchOptions';

export default function sendBase64Data(baseArray) {
	const base64Obj = {baseData: baseArray};

	return fetch(
		`${ENDPOINT_URL}/childtest/audioanswers`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify(base64Obj),
		})
	)
		.then((response) => {
			if (!response.ok) {
				throw response;
			}
			return response.json();
		})
		.catch((err) => {
			return Promise.reject(apiErrorHandling(err));
		});
}
const apiErrorHandling = (response) => {
	return response.text().then((errorMessage) => {
		try {
			const errorJson = JSON.parse(errorMessage);
			if (errorJson.error === 'TokenExpiredError') {
				localStorage.removeItem('authtoken-screening');
				window.location.href = `/login`;
			}
			return errorJson;
		} catch (e) {}

		return errorMessage;
	});
};
