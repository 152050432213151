import getFetchOptions from 'utils/getFetchOptions';

import {ENDPOINT_URL} from 'constants/index';

export default function getTestInfo() {
	return fetch(
		`${ENDPOINT_URL}/testinfo`,
		getFetchOptions({
			method: 'GET',
		})
	).then((response) => {
		if (!response.ok) {
			throw response;
		}
		return response.json();
	});
}
