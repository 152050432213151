import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">{`Vendepunktet`}</h2>
		<p>
			{`«Så hva har du å si til dette?» spør pappa opprørt. Jeg vet ikke 
			hva jeg skal si. Uansett hva jeg svarer nå så blir det bråk. Det 
			krymper seg i magen og i brystet, og angsten brer seg til kjeven og 
			armene. I løpet av få sekunder innser jeg at jeg er i skikkelig 
			trøbbel, og at dette ikke vil gå over med det første.`}
		</p>
		<p>
			{`Mamma hadde blitt oppringt av senterlederen mens jeg var på vei 
			hjem. Det var forsvunnet ting fra butikken jeg hadde vært på. Jeg 
			er akkurat kommet inn døra hjemme når jeg blir konfrontert med 
			anklagene. «Jeg har ikke tatt noe», svarer jeg. «Det er bare å 
			sjekke alt jeg har på meg. Jeg har ikke tatt noe».`}
		</p>
		<p>
			{`Og jeg vet at de ikke vil finne noe hverken i skolesekken eller i 
			lommene. Mamma tømmer ut alt innholdet i sekken mens pappa saumfarer 
			jakkelommene med brå bevegelser. «Hvor har du fått tak i denne?» 
			spør mamma mens hun tar ut én og én ting fra sminkebagen min. «Den 
			der kjøpte jeg for tre måneder siden, minst. Ser du ikke at den 
			snart er oppbrukt?» svarer jeg. Og sånn holder vi på til alt fra 
			sminke til gymtøy er gjennomgått. Ingenting av det var nytt, og jeg 
			kunne gjøre rede for alt. Mamma og pappa veksler blikk, og jeg aner 
			en viss usikkerhet hos dem.`}
		</p>
		<p>
			{`«Er det noe du vil fortelle oss? Nå har du sjansen til å fortelle 
			oss sannheten. Vi vil høre den fra deg selv, ikke fra andre, om du 
			har gjort noe dumt» sier pappa. «Du ser jo selv at jeg ikke har tatt 
			noe», svarer jeg. «Senterlederen sier at de har sterk mistanke om at 
			du har stålet. De ringer jo ikke bare på tull og påstår noe så 
			alvorlig? Om du lyver for oss nå, så er det et alvorlig 
			tillitsbrudd» fortsetter han.`}
		</p>
		<p>
			{`Nå kjenner jeg ikke bare på angsten, men også på fortvilelsen over 
			at de ikke tror på meg, enda de ikke finner noe. At de tror mer på 
			andre enn på meg. Dette er en helt ny opplevelse. Jeg vil gråte, men 
			er samtidig så redd at gråten ikke kommer ut.`}
		</p>
		<p>
			{`«Senterlederen vil gjerne snakke med oss, så vi skal dra dit nå», 
			sier mamma. «Men skal vi ikke spise først?» spør jeg enda jeg ikke 
			er sulten i det hele tatt. Middagslukten fra kjøkkenet gir likevel 
			håp om at alt kan settes på pause. Jeg føler meg uvel. Selv ikke 
			tikka masala, yndlingsretten min, frister akkurat nå. «Middagen får 
			vente», svarer mamma. «Vi må få denne saka ut av verden så fort som 
			mulig».`}
		</p>
		<p>
			{`Det er merkelig å kjøre samme veien tilbake som jeg for under en 
			time siden hadde kjørt med bussen. Jeg hadde vært en kjapp tur innom 
			kjøpesenteret etter skoletid, og tok en milkshake sammen med noen fra 
			klassen. Det er stille i bilen. Jeg har musikken på lavt så jeg kan 
			høre hvis de snakker seg imellom. Men de veksler ikke et ord. Jeg 
			prøver å lese ansiktet til mamma i sidespeilet, mens jeg kan studere 
			pappa mer direkte fra baksetet. Han ser veldig alvorlig og 
			konsentrert ut der han kjører. Han er sikkert veldig lettet for at 
			de ikke fant noe. Begge tenker sikkert på hva de skal si på møtet.`}
		</p>
		<p>
			{`Inne på senteret går vi inn ei grønn dør som er skiltet med 
			«Ledelse». Jeg har knapt lagt merke til denne tidligere, enda jeg 
			har gått forbi der flere ganger på vei til toalettet. Ei 
			securitasvakt spør om vi skal møte senterlederen, og viser oss vei. 
			Vi kommer inn i en liten korridor med mange dører, og i enden står 
			ei dør på gløtt. Inne på kontoret sitter to damer og en annen 
			securitasvakt. Hun ene har jeg aldri sett på ordentlig, bare i 
			media. Hun har langt lyst hår, knallrøde negler og lepper, og er 
			stilig kledd. Hun andre kjenner jeg igjen fra butikken. Hun har 
			brunt hår som er samlet i en lang hestehale, og er ganske pen. 
			Securitasvakta kjenner jeg også igjen. Han bruker noen ganger å gå 
			rundt, og noen ganger bruker han å sitte i service-skranken midt 
			inne i senteret i 1.etasjen.`}
		</p>
		<p>
			{`Vi setter oss rundt et stort møtebord, og senterlederen innleder 
			med å fortelle at det alltid er like trist når unge mennesker blir 
			tatt for butikktyveri. «Vi anmelder samtlige fordi det ellers vil 
			være umulig å velge hvem som skal anmeldes og hvem som skal slippe». 
			Butikkdama tar ordet og sier at «Et mammahjerte brister når jeg må 
			anmelde noen som er tatt for første gang. Det er veldig vondt. Men 
			hva skal jeg gjøre? Jeg må selv betale for å få varer inn til 
			butikken min, og jeg må fortsatt betale lønn til de ansatte og 
			betale for leie av butikklokalene – selv om folk stjeler og jeg 
			taper inntekt. Det er så jævlig urettferdig at jeg skal lide for 
			at enkelte ikke skjønner forskjellen på mitt og ditt».  `}
		</p>
		<p>
			{`Nå er det securitasvakten som tar ordet: «Etter mange års erfaring 
			kan vi se potensielle butikktyver. Det handler om hvordan de 
			oppfører seg i butikken, selv om de tror at de oppfører seg helt 
			normalt. Og mens gutter typisk stjeler alene, så stjeler jentene 
			når de er sammen med andre. Et vanlig eksempel er når tre venninner 
			er inne samtidig og to gjør hva de kan for å avlede oppmerksomheten 
			mens den tredje stjeler. Da vet vi at det er en vel gjennomtenkt 
			handling». Senterlederen overtar ordet, og veksler mellom å se på 
			meg og foreldrene mine: «Noen ganger blir én person i en vennegjeng 
			tatt. Men vi vet hvem de andre er. Da vil også de andre vennene bli 
			møtt med mistenksomhet neste gang de er i butikkene våre, selv om de 
			er uskyldige. Alle blir stemplet dersom de er i venneflokken til en 
			butikktyv».`}
		</p>
		<p>
			{`Jeg aner nå et lite smil hos mamma. Hun vet at dette ikke angår 
			meg. Jeg opptrer ikke i noen gjeng. Selv om vi har bodd på dette 
			stedet i to år nå, har det vært vanskelig å få nye venner. Dronninga 
			i klassen og hoffet hennes bitch-blikket meg ganske lenge, og guttene 
			er noen skikkelige drama queens som sprer falske rykter om folk. 
			Heldigvis har det blitt bedre i det siste, og jeg har liksom blitt 
			mer akseptert. I morgen kveld skal jeg til og med få komme hjem på 
			filmkveld hos dronninga. Men jeg savner gammelklassen.`}
		</p>
		<p>
			{`«Uansett om den vi fersker er fylt 15 år eller ikke, så anmelder 
			vi saka til politiet», fortsetter senterlederen. «Du blir da 
			registrert som anmeldt, og må møte hos politiet. Barnevernet blir 
			ofte også kontaktet».`}
		</p>
		<p>
			{`Det virker som de har snakket om dette mange ganger før. 
			Senterlederen, butikksjefen og securitasvakten snakker ikke i munnen 
			på hverandre, men de overtar for hverandre som i et stafettløp. Jeg 
			hører på måten mamma og pappa puster på at de har tenkt å si noe nå, 
			de også. Men før noen av dem kommer så langt, fortsetter 
			securitasvakten mens han ser på meg: «Hvis du er fylt 15 kan du bli 
			straffet med bøter. Da blir du registrert som straffet person, men i 
			enkelte tilfeller kan du være heldig å bare få påtaleunnlatelse. Det 
			vil si at du er skyldig, men ikke får straff denne gangen».`}
		</p>
		<p>
			{`Senterlederen ser på pappa, selv om det er meg hun snakker til: 
			«Husk at hvis du blir tatt for å stjele kan også mulighetene dine 
			videre i livet bli begrenset. Du kan få utsettelse på når du kan ta 
			mopedførerbeviset og førerkortet, i tillegg kan du få problemer med å 
			få jobber eller studier som krever sikkerhetsklarering». Så flytter 
			hun blikket til meg og spør: «Er du fylt 15?».`}
		</p>
		<p>
			{`Nå har pappa fått nok. Han vrir seg i stolen og kremter høyt. 
			Stemmen hans skjelver når han begynner å prate, og det gjør den bare 
			når han er sint. «Og dere er kanskje også kjent med at det er 
			straffbart å fremsette falske beskyldninger og fabrikkere bevis? Er 
			det ikke tre år som er strafferammen for slikt? Det skal jeg nok 
			finne ut av». Det blir plutselig en annen temperatur i rommet. 
			Senterlederen prøver å si noe, men blir avbrutt av pappa. «Vi 
			ransaket skolesekken og jakkelommene hennes med det samme hun kom 
			inn døra hjemme. Fra vi ble varslet og til hun kom hjem gikk det 
			veldig kort tid, og hun hadde ikke hatt sjans til å gjemme noe. Og 
			bare så det er sagt så fant vi ingenting som ikke er hennes. 
			Ingenting! Derfor er det veldig merkelig å sitte og høre på alt dere 
			lirer av dere – uten at dere har noe som helst belegg for det dere 
			beskylder henne for».`}
		</p>
		<p>
			{`Jeg kjenner på en enorm lettelse. Mamma og pappa tror på meg 
			likevel. Og pappa har jo helt rett. Det er ingen som har tatt meg 
			med stjålne ting`}
		</p>
		<p>
			{`Selv om det ennå er seint på ettermiddagen, føles det som at det 
			er seint på kvelden. Det har skjedd så mye. Jeg rekker å kjenne på 
			at jeg er sulten, at jeg gleder meg til å komme hjem og spise, og at 
			det er godt at det er helg. Men så kommer Securitasvakta med ordene 
			som får hele verden min til å falle i grus. `}
		</p>
		<p>
			{`«Vi har bevis. Ikke bare fra i dag, men også fra to tidligere 
			tilfeller, og i ulike butikker. Som sagt så stjeler jenter helst i 
			flokk. Men det har ikke skjedd i dette tilfellet. Vi har hatt 
			mistanke over tid, og mistanken vår er blitt fanget opp og bekreftet 
			gjennom opptak fra våre overvåkningskamera».`}
		</p>
		<p>
			{`Kroppen er paralysert. Ansiktet er helt numment, som om jeg har 
			mistet alt blodet. Mamma og pappa ser på meg med vantro blikk. 
			Stemmene i rommet er langt borte. Det er som at jeg ikke skjønner 
			hva som skjer rundt meg. Jeg kjenner på skammen både for det jeg har 
			gjort og for at jeg har løyet til dem. Og oppi alt kjenner jeg på en 
			lettelse.`}
		</p>
	</span>
);
export default text;
