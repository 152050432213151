import React from 'react';
import {connect} from 'react-redux';

import {updateObject} from 'utils/reducerUtils';
import strings from 'utils/strings';

import classNames from 'classnames';
import AudioPlayer from 'components/AudioPlayer';

import previewVideo from './assets/tutorial.mp4';

import introNo from 'assets/sounds/no/2 2 Ordkjedetest-her-ser-du-4-ovingsoppgaver.mp3';
import introNn from 'assets/sounds/nn/2 2 Ordkjedetest-her-ser-du-4-ovingsoppgaver.mp3';

import starterTestenNo from 'assets/sounds/no/2 7 Ordkjedetest-Starter-testen.mp3';
import starterTestenNn from 'assets/sounds/nn/2 7 Ordkjedetest-Starter-testen.mp3';

import assignmentsNo from './data/tutorial-no';
import assignmentsNn from './data/tutorial-nn';

const languages = {
	no: {
		intro: introNo,
		starterTesten: starterTestenNo,
		assignments: assignmentsNo,
	},
	nn: {
		intro: introNn,
		starterTesten: starterTestenNn,
		assignments: assignmentsNn,
	},
};

const whiteSpaceRegex = /\s+/g;

const stripWhiteSpace = str => str.replace(whiteSpaceRegex, '');
const removeDuplicateWhiteSpace = str => str.replace(whiteSpaceRegex, ' ');
const getNumberOfWhiteSpaces = str => {
	const matches = str.match(whiteSpaceRegex);

	if (!matches) {
		return 0;
	}

	return matches.length;
};

const WHITE_SPACE_LIMIT = 3;

class Test extends React.Component {
	constructor() {
		super();

		const assets = languages[strings.getLanguage()];

		this.starterTestenSound = assets.starterTesten;
		this.audio = assets.intro;
		this.assignments = assets.assignments;

		this.audioRefs = {};

		const results = {};

		for (const assignment of this.assignments) {
			results[assignment.id] = assignment.text;
		}

		this.state = {
			results,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {results} = this.state;

		let allAssignmentsCompleted = true;
		for (const result in results) {
			if (results.hasOwnProperty(result)) {
				const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
					results[result]
				);
				if (numberOfWhiteSpaces !== WHITE_SPACE_LIMIT) {
					allAssignmentsCompleted = false;
					break;
				}
			}
		}

		let videoRef;

		return (
			<div className="lit-test lit-word-chain">
				<p>
					<AudioPlayer
						autoplay
						inline
						onPause={() => {
							videoRef.play();
						}}
						refs={this.audioRefs}
						src={this.audio}
					/>
					{strings.ordkjedetestTutorial}
				</p>
				<div className="lit-preview">
					<p className="lit-preview__label">{strings.howTo}</p>
					<video
						className="lit-preview__video"
						onClick={() => {
							videoRef.play();
						}}
						ref={ref => {
							videoRef = ref;
						}}
						src={previewVideo}
					/>
				</div>
				<div className="lit-wrapper--thin tutorial">
					<div className="lit-word-chains">
						{this.assignments.map((assignment, i) => {
							const {audio, answer, count, id, text} = assignment;
							let completedAssignment = false;
							let isCorrect = false;
							const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
								results[id]
							);
							if (numberOfWhiteSpaces === WHITE_SPACE_LIMIT) {
								completedAssignment = true;
								isCorrect = results[id] === answer;
							}

							const onChange = ev => {
								let value = ev.target.value;
								value = value.trim();
								value = removeDuplicateWhiteSpace(value);

								const hasUserEnteredOtherCharacters =
									stripWhiteSpace(value) !== text;
								const numberOfWhiteSpaces = getNumberOfWhiteSpaces(
									value
								);
								if (
									hasUserEnteredOtherCharacters ||
									numberOfWhiteSpaces > WHITE_SPACE_LIMIT
								) {
									return;
								}

								this.setResults(id, value);
							};
							const onFocus = () => {
								const inputElement = document.getElementById(i);

								setTimeout(() => {
								inputElement.focus();
								inputElement.setSelectionRange(0, 0);
								  }, 0);
								
							};

							return (
								<div className="input-wrapper" key={id}>
									<div
										className={classNames({
											'lit-input inline': true,
											completed: completedAssignment,
										})}
									>
										<label className="lit-input__label tiny lit-input__label--count">
											{id}
										</label>
										<input
											id={i}
											autoFocus={i === 0}
											className={classNames({
												'lit-input__field': true,
											})}
											onChange={onChange}
											onFocus={onFocus}
											spellCheck={false}
											value={results[id]}
										/>
									</div>
									{completedAssignment && i > 0 && (
										<p className="comment">
											{isCorrect ? (
												<span>
													{/* <AudioPlayer
														autoplay
														inline
													/> */}
													{strings.ordkjedeHeltRiktig}
												</span>
											) : (
												<span>
													<AudioPlayer
														// autoplay
														inline
														refs={this.audioRefs}
														src={audio}
													/>

													{strings.formatString(
														strings.ordkjedeIkkeRiktig,
														count
													)}
													<strong>
														{answer
															.split(' ')
															.join(', ')}
													</strong>
												</span>
											)}
										</p>
									)}
								</div>
							);
						})}
					</div>
					{allAssignmentsCompleted && (
						<p className="instruction">
							<AudioPlayer
								autoplay
								inline
								refs={this.audioRefs}
								src={this.starterTestenSound}
							/>
							{strings.ordkjedeStarterTesten}
						</p>
					)}
					{allAssignmentsCompleted && (
						<button
							className="lit-btn bg-primary"
							onClick={() => onNextStep(results)}
						>
							{strings.start}
						</button>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);
