import React from 'react';
import queryString from 'query-string';

import './styles.scss';
import Logo from 'assets/logo-dysmate.png';
import {IS_NORMING_VERSION} from 'constants/index';

const parsedSearch = queryString.parse(window.location.search);

const error = parsedSearch.error;
const flag = 'JJiisTezRREXXDSSERllliOR321Frrsaas';

class LandingPageSecret extends React.Component {
	constructor() {
		super();

		this.state = {
			testKey: '',
		};
	}
	render() {
		const {testKey} = this.state;

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="Literate Logo"
						/>
					</h1>

					<div className="right">
						<a className="lit-link" href="http://admin.literate.no">
							Administrator
						</a>
					</div>
				</header>
				{IS_NORMING_VERSION && (
					<>
						<h1 className="lit-landing-page-title">Ungdomstest</h1>
						<p className="lit-landing-page-subtitle">
							NORMERINGSVERSJON
						</p>
					</>
				)}
				{!IS_NORMING_VERSION && (
					<>
						<h1 className="lit-page-title">Ungdomstest</h1>
					</>
				)}
				<p className="lit-page-description">
					Skriv inn din testnøkkel i feltet under
				</p>
				<div className="lit-wrapper--article">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							window.location.href = `/login?key=${testKey}&id=${flag}`;
						}}
					>
						<div className="lit-input inline">
							<label className="lit-input__label tiny">
								Din testnøkkel:
							</label>
							<input
								className="lit-input__field"
								onChange={(event) => {
									const value = event.target.value;
									this.setState({
										testKey: value,
									});
								}}
								placeholder="F.eks 2D84FCA5"
								required
								type="text"
								value={testKey}
							/>
							<button className="lit-btn bg-secondary">
								Start
							</button>
						</div>
						{Boolean(error) && (
							<span>En gyldig nøkkel kreves for å logge inn</span>
						)}
					</form>
				</div>
			</div>
		);
	}
}

export default LandingPageSecret;
