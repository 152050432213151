import React from 'react';
import PropTypes from 'prop-types';
import strings from 'utils/strings';

const BooleanRadio = (props) => {
	const {name, onChange, value} = props;

	return (
		<div className="lit-input__field lit-radios">
			<span className="lit-radio">
				<input
					checked={value === false}
					className="lit-radio__field"
					id={`${name}False`}
					name={name}
					onChange={() => {
						onChange(false);
					}}
					required
					type="radio"
				/>
				<label className="lit-radio__label" htmlFor={`${name}False`}>
					{strings.no}
				</label>
			</span>
			<span className="lit-radio">
				<input
					checked={value === true}
					className="lit-radio__field"
					id={`${name}True`}
					name={name}
					onChange={() => {
						onChange(true);
					}}
					required
					type="radio"
				/>
				<label className="lit-radio__label" htmlFor={`${name}True`}>
					{strings.yes}
				</label>
			</span>
		</div>
	);
};

BooleanRadio.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
};

export default BooleanRadio;
