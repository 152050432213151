import getFetchOptions from 'utils/getFetchOptions';

import {ENDPOINT_URL} from 'constants/index';

export default function getTestKeyInfo(key) {
	return fetch(
		`${ENDPOINT_URL}/testkeyinfo`,
		getFetchOptions({
			method: 'POST',
			body: JSON.stringify({key}),
		})
	).then((response) => {
		if (!response.ok) {
			throw response;
		}
		return response.json();
	});
}